var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "2 0 14 14" } },
    [
      _c("title", [_vm._v("Position")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "7.55",
              y: "8.58",
              width: "2.76",
              height: "2.19",
              rx: "0.17",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M7.8,12.11H6.71A.68.68,0,0,1,6,11.43V10.32a.2.2,0,1,1,\n        .39,0v1.11a.29.29,0,0,0,.29.29H7.8a.2.2,0,0,1,.19.2A.19.19,0,0,1,7.8,12.11Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M11.15,12.11H10.07a.19.19,0,0,1-.2-.19.2.2,0,0,1,\n        .2-.2h1.08a.29.29,0,0,0,.29-.29V10.32a.2.2,0,1,1,.39,0v1.11A.68.68,0,0,1,11.15,12.11Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M6.23,9.23A.2.2,0,0,1,6,9V7.92a.69.69,0,0,1,.68-.68H7.8a.2.2,0,0,\n        1,.19.2.2.2,0,0,1-.19.19H6.71a.29.29,0,0,0-.29.29V9A.2.2,0,0,1,6.23,9.23Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M11.63,9.23A.2.2,0,0,1,11.44,9V7.92a.29.29,0,0,0-.29-.29H10.07a.2.2,0,\n        1,1,0-.39h1.08a.69.69,0,0,1,.68.68V9A.2.2,0,0,1,11.63,9.23Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M15.35,3.91H2.65A.65.65,0,0,0,2,4.56v8.88a.65.65,0,0,0,.65.65h12.7a.65.65,\n        0,0,0,.65-.65V4.56A.65.65,0,0,0,15.35,3.91Zm-8,.69a.54.54,0,0,1,0,1.08.54.54,\n        0,1,1,0-1.08ZM5.4,4.6a.54.54,0,1,1-.54.54A.54.54,0,0,1,5.4,4.6Zm-1.92,0a.54.54,\n        0,1,1-.54.54A.54.54,0,0,1,3.48,4.6Zm11.74,8.71H2.78V6.44H15.22Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }